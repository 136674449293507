import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import Footer from "../components/Footer.js";
import Navbar_inner from "../components/Navbar_inner.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import {
  getCurAddr,
  addactivity,
  getpresaledetails,
  updateuserrec,
  checkaccept
} from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";
import syncEach from "sync-each";
import icoabi from "../ABI/presaleABI";
import bonjourapi from "../ABI/bonjourABI";
import busdabi from "../ABI/busdABI";
import tokenabi from "../ABI/bonjourtokenABI";
import config from "../config/config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BigNumber from "bignumber.js";
import { InlineShareButtons } from "sharethis-reactjs";
import { type WalletClient, useWalletClient } from 'wagmi'

let count = 0;
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Dashboard(props) {
  let data = {
    name: "ID 56001",
    children: [
      {
        name: "ID 56002(5)",
      },
      {
        name: "ID 56003(12)",
      },
      {
        name: "ID 56004(8)",
      },
    ],
  };

  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })

  useEffect(() => {
    window.addEventListener("resize", larg);
    getdetails();
  }, [walletClient]);

  async function larg() {
    setwidth(window.innerWidth - 50);
    setheight(window.innerHeight - 50);
  }

  const [selected, setSelected] = useState({});
  const [allselected, setallSelected] = useState({});
  const [width, setwidth] = useState(window.innerWidth - 50);
  const [height, setheight] = useState(window.innerHeight - 50);
  const [userdata, setdata] = useState({});
  const [withdrawprogress, setwithdrawprogress] = useState("false");
  const [mynetwork, setmynetwork] = useState([]);
  const [level1, setlevel1] = useState([]);
  const [level2, setlevel2] = useState([]);
  const [level3, setlevel3] = useState([]);
  const [level4, setlevel4] = useState([]);
  const [airdropstatus,setairdropstatus] = useState(false);
  const [airdropisclaim,setairdropisclaim] = useState(false);
  const [presaledetails, setpresaledetails] = useState(0);
  const [selected_id, setselected_id] = useState("");
  const [treeprogress, settreeprogress] = useState(true);
  const [userdetails, setuserdetails] = useState({});
  const [investamt, setinvestamt] = useState(0);
  const [progress, setprogress] = useState("false");
  const [downlinesecond, setdownlinesecond] = useState([]);
  const [downlinefirst, setdownlinefirst] = useState([]);
  const [downlinethird, setdownlinethird] = useState([]);
  const [downlinefourth, setdownlinefourth] = useState([]);
  const [claimprogress, setclaimprogress] = useState("");
  const [usercount, setusercount] = useState(0);
  const [searchid, setsearchid] = useState("");
  const [globalprogress, setglobalprogress] = useState("false");
  const [presalestatus, setpresalestatus] = useState(false);
  const [availamt, setavailamt] = useState(0);
  const [tokenprice, settokenprice] = useState(0);
  const [myrole,setmyrole] = useState(0);
  const [claimnable,setclaimnable] = useState(false);
  const [tableload,settableload] = useState(false);
  const [powdervalue,setpowdervalue] = useState(0);
  const [secondvalue,setsecondvalue] = useState(0);
  const [remainingvalue,setremainingvalue] = useState(0);
  const [downcount,setdowncount] = useState(0);
  const [isEligible,setisEligible] = useState(false);
  const [isEligible1,setisEligible1] = useState(false);
  const [isNewuser,setisNewuser] = useState(false);
  const [acceptcondition,setacceptcondition] = useState(false);
  const [acceptstatus,setacceptstatus] = useState(false);
  const [airdropprogress,setairdropprogress] = useState("false");

  async function getdetails() {
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        setdata(datas);
        if (datas.userdetails && datas.userdetails.isExist) {
          let inputadd = {
            "address" : datas.address,
            "userid" : datas.userdetails.userid
          }
          let acceptdetails = await checkaccept(inputadd);
          if(acceptdetails && acceptdetails.data && acceptdetails.data.data && acceptdetails.data.data.acceptstatus ){
            setacceptstatus(acceptdetails.data.data.acceptstatus);
            setacceptcondition(acceptdetails.data.data.acceptstatus);
          }
          updateuserrec(inputadd);
          getuserdetails(datas.address);
        } else {
          window.location.href = "/join-now";
        }
      } else {
        //window.location.href = "/";
      }
  }

  const idchange = (e) => {
    setsearchid(e.target.value);
  };

  const hadlechange = (e) => {
    setacceptcondition(e.target.checked);
  }

  async function handleClick(event, node, search = {}) {
    try {
      if (
        (node && node != null && node != undefined && node != "") ||
        (search != {} && search != "" && search != null && search != undefined)
      ) {
        let myArray =
          node &&
          node != null &&
          node != undefined &&
          node != "" &&
          node.split(" ");
        let userid =
          node && node != null && node != undefined && node != ""
            ? myArray[1]
            : search;
        if (selected_id == userid) {
          setSelected(allselected);
          setselected_id("");
        } else {
          settreeprogress(true);
          setselected_id(userid);
          let datas = await getCurAddr();
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );
          const multicall = new Multicall({
            web3Instance: web3,
          });
          var bonjourcontract = new web3.eth.Contract(
            bonjourapi,
            config.bonjourcontract
          );
          let addr = await bonjourcontract.methods
            .getUserAddress(userid)
            .call();
          var contractCallContext = [
            {
              reference: "details",
              contractAddress: config.bonjourcontract,
              abi: bonjourapi,
              calls: [
                {
                  reference: "userprofile",
                  methodName: "users",
                  methodParameters: [addr],
                },
                {
                  reference: "referral",
                  methodName: "getAllReferralAddress",
                  methodParameters: [addr],
                },
              ],
            },
          ];
          const results = await multicall.call(contractCallContext);
          var userprofile = await getFormatMulticall(results, "userprofile", 0);
          var downlinenode = await getFormatMulticall(results, "referral", 0);
          if (userprofile[0]) {
            let userexist = true;
            let levelIncomeEligible = await bignumber(userprofile[2]);
            //**********************downline1************************//
            let downline1 = [];
            let downline2arr = [];
            let downline3arr = [];
            let downline4arr = [];
            let downline5arr = [];
            let downline2 = [];
            let downline3 = [];
            let downline4 = [];
            if (downlinenode.length > 0) {
              for (let node in downlinenode) {
                var downlineCallContext = [
                  {
                    reference: "details",
                    contractAddress: config.bonjourcontract,
                    abi: bonjourapi,
                    calls: [
                      {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downlinenode[node]],
                      },
                      {
                        reference: "referral",
                        methodName: "getAllReferralAddress",
                        methodParameters: [downlinenode[node]],
                      },
                    ],
                  },
                ];
                const _results = await multicall.call(downlineCallContext);
                var _userprofile = await getFormatMulticall(
                  _results,
                  "userprofile",
                  0
                );
                var _downlinenode = await getFormatMulticall(
                  _results,
                  "referral",
                  0
                );
                let _userid = await bignumber(_userprofile[1]);
                let _currlevel = await bignumber(_userprofile[2]);
                let _downline = {
                  address: downlinenode[node],
                  level: _currlevel,
                  userid: _userid,
                  downlinenode: _downlinenode,
                };
                downline1.push(_downline);
                downline2arr = downline2arr.concat(_downlinenode);
                if (parseFloat(node) + 1 == downlinenode.length) {
                  setdownlinefirst(downline1);
                }
              }
            }
            //**********************downline2************************//
            if (downline2arr.length > 0) {
              for (let node1 in downline2arr) {
                var downlineCallContext1 = [
                  {
                    reference: "details",
                    contractAddress: config.bonjourcontract,
                    abi: bonjourapi,
                    calls: [
                      {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline2arr[node1]],
                      },
                      {
                        reference: "referral",
                        methodName: "getAllReferralAddress",
                        methodParameters: [downline2arr[node1]],
                      },
                    ],
                  },
                ];
                const _results1 = await multicall.call(downlineCallContext1);
                var _userprofile1 = await getFormatMulticall(
                  _results1,
                  "userprofile",
                  0
                );
                var _downlinenode1 = await getFormatMulticall(
                  _results1,
                  "referral",
                  0
                );
                let _userid1 = await bignumber(_userprofile1[1]);
                let _currlevel1 = await bignumber(_userprofile1[2]);
                let _downline1 = {
                  address: downline2arr[node1],
                  level: _currlevel1,
                  userid: _userid1,
                  downlinenode: _downlinenode1,
                };
                downline2.push(_downline1);
                downline3arr = downline3arr.concat(_downlinenode1);
                if (parseFloat(node1) + 1 == downline2arr.length) {
                  setdownlinesecond(downline2);
                }
              }
            }
            //**********************downline3************************//
            if (downline3arr.length > 0) {
              for (let node2 in downline3arr) {
                var downlineCallContext2 = [
                  {
                    reference: "details",
                    contractAddress: config.bonjourcontract,
                    abi: bonjourapi,
                    calls: [
                      {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline3arr[node2]],
                      },
                      {
                        reference: "referral",
                        methodName: "getAllReferralAddress",
                        methodParameters: [downline3arr[node2]],
                      },
                    ],
                  },
                ];
                const _results2 = await multicall.call(downlineCallContext2);
                var _userprofile2 = await getFormatMulticall(
                  _results2,
                  "userprofile",
                  0
                );
                var _downlinenode2 = await getFormatMulticall(
                  _results2,
                  "referral",
                  0
                );
                let _userid2 = await bignumber(_userprofile2[1]);
                let _currlevel2 = await bignumber(_userprofile2[2]);
                let _downline2 = {
                  address: downline3arr[node2],
                  level: _currlevel2,
                  userid: _userid2,
                  downlinenode: _downlinenode2,
                };
                downline3.push(_downline2);
                downline4arr = downline4arr.concat(_downlinenode2);
                if (parseFloat(node2) + 1 == downline3arr.length) {
                  setdownlinethird(downline3);
                }
              }
            }
            //**********************downline4************************//
            if (downline4arr.length > 0) {
              for (let node3 in downline4arr) {
                var downlineCallContext3 = [
                  {
                    reference: "details",
                    contractAddress: config.bonjourcontract,
                    abi: bonjourapi,
                    calls: [
                      {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline4arr[node3]],
                      },
                      {
                        reference: "referral",
                        methodName: "getAllReferralAddress",
                        methodParameters: [downline4arr[node3]],
                      },
                    ],
                  },
                ];
                const _results3 = await multicall.call(downlineCallContext3);
                var _userprofile3 = await getFormatMulticall(
                  _results3,
                  "userprofile",
                  0
                );
                var _downlinenode3 = await getFormatMulticall(
                  _results3,
                  "referral",
                  0
                );
                let _userid3 = await bignumber(_userprofile3[1]);
                let _currlevel3 = await bignumber(_userprofile3[2]);
                let _downline3 = {
                  address: downline4arr[node3],
                  level: _currlevel3,
                  userid: _userid3,
                  downlinenode: _downlinenode3,
                };
                downline4.push(_downline3);
                downline5arr = downline5arr.concat(_downlinenode3);
                if (parseFloat(node3) + 1 == downline4arr.length) {
                  setdownlinefourth(downline4);
                }
              }
            }
            let line1arr = [];
            if (downline1.length > 0) {
              syncEach(
                downline1,
                async function (items, next) {
                  if (
                    items &&
                    items.downlinenode &&
                    items.downlinenode.length > 0
                  ) {
                    let subline = [];
                    syncEach(
                      items.downlinenode,
                      async function (subitems, next1) {
                        let IndexVal = downline2.findIndex(
                          (val) => val.address == subitems
                        );
                        if (
                          IndexVal >= 0 &&
                          downline2[IndexVal].downlinenode &&
                          downline2[IndexVal].downlinenode.length > 0
                        ) {
                          let subline2 = [];
                          syncEach(
                            downline2[IndexVal].downlinenode,
                            async function (thir_items, next2) {
                              let IndexVal1 = downline3.findIndex(
                                (val) => val.address == thir_items
                              );
                              if (
                                IndexVal1 >= 0 &&
                                downline3[IndexVal1].downlinenode &&
                                downline3[IndexVal1].downlinenode.length > 0
                              ) {
                                let IndexVal = downline3.findIndex(
                                  (val) => val.address == thir_items
                                );
                                let user1 = {
                                  name: "ID " + downline3[IndexVal].userid,
                                };
                                subline2.push(user1);
                                next2();
                                //let subline3 = [];
                                // syncEach(downline3[IndexVal1].downlinenode, async function (fourth_items,next3) {
                                //   let Index_Val = downline4.findIndex(val => val.address == fourth_items);
                                //     let user22 = {
                                //         name : "ID "+downline4[Index_Val].userid+" ("+downline4[Index_Val].level+")"
                                //     }
                                //     subline3.push(user22);
                                //     next3();
                                // },function( error , success){
                                //   let mainline = {
                                //       "name": "ID "+downline3[IndexVal1].userid+" ("+downline3[IndexVal1].level+")",
                                //       "children": subline3
                                //     }
                                //     setlevel3(mainline);
                                //     subline2.push(mainline);
                                //   next2();
                                // })
                              } else {
                                let IndexVal = downline3.findIndex(
                                  (val) => val.address == thir_items
                                );
                                let user1 = {
                                  name: "ID " + downline3[IndexVal].userid,
                                };
                                subline2.push(user1);
                                next2();
                              }
                            },
                            function (error, success) {
                              let mainline = {
                                name: "ID " + downline2[IndexVal].userid,
                                children: subline2,
                              };
                              setlevel2(mainline);
                              subline.push(mainline);
                              next1();
                            }
                          );
                        } else {
                          var IndexValue = downline2.findIndex(
                            (val) => val.address == subitems
                          );
                          let user1 = {
                            name: "ID " + downline2[IndexValue].userid,
                          };
                          subline.push(user1);
                          next1();
                        }
                      },
                      function (errs, t_Items) {
                        let mainline = {
                          name: "ID " + items.userid,
                          children: subline,
                        };
                        setlevel1(mainline);
                        line1arr.push(mainline);
                        next();
                      }
                    );
                  } else {
                    let mainline = {
                      name: "ID " + items.userid,
                      children: [],
                    };
                    setlevel1(mainline);
                    setlevel2([]);
                    line1arr.push(mainline);
                    next();
                  }
                },
                function (err, transformedItems) {
                  let mainlinearr = {
                    name: "ID " + userid,
                    children: line1arr,
                  };
                  setSelected(mainlinearr);
                  settreeprogress(false);
                }
              );
            } else {
              let mainlinearr = {
                name: "ID " + userid,
                children: [],
              };
              setSelected(mainlinearr);
              settreeprogress(false);
            }
          }
        }
      }
    } catch (errs) {
      settreeprogress(false);
      if (
        search != {} &&
        search != "" &&
        search != null &&
        search != undefined
      ) {
        toastAlert("error", "Invalid User Id", "network");
      }
    }
  }

  async function claimairdropamt() {
    setairdropprogress("true");
    try {
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        if (datas.userdetails && datas.userdetails.isExist) {
            let web3 = new Web3(
              datas &&
              datas.provider &&
              datas.provider != null &&
              datas.provider != undefined &&
              datas.provider != ""
                ? datas.provider
                : window.ethereum
            );
            var bonjourContract = new web3.eth.Contract(
              bonjourapi,
              config.bonjourcontract
            );
            let register = await bonjourContract.methods
              .claimToken()
              .send({
                from: datas.address,
              });
            if (register && register.status && register.transactionHash) {
              let act_data = {
                address: datas.address,
                userid: datas.userdetails.userid,
                amount: 0,
                event: "airdropclaim",
                hash: register.transactionHash,
              };
              await addactivity(act_data);
              toastAlert(
                "success",
                "Airdrop Claimed Successfully",
                "network"
              );
              setairdropprogress("false");
              window.location.reload();
            } else {
              setairdropprogress("false");
              toastAlert("error", "Failed , try again later", "network");
            }
        } else {
          setairdropprogress("false");
          toastAlert("error", "Join First to proceed Further", "network");
          window.location.href = "/join-now";
        }
      } else {
        setairdropprogress("false");
        toastAlert("error", "Please select BSC chain network", "network");
      }
    } catch (err) {
      setairdropprogress("false");
    }
  }

  async function claimglobalearnamt(active) {
    setglobalprogress("true");
    try {
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        if (datas.userdetails && datas.userdetails.isExist) {
          if (parseFloat(datas.userdetails.globalPoolIncome) > 0) {
            let web3 = new Web3(
              datas &&
              datas.provider &&
              datas.provider != null &&
              datas.provider != undefined &&
              datas.provider != ""
                ? datas.provider
                : window.ethereum
            );
            var bonjourContract = new web3.eth.Contract(
              bonjourapi,
              config.bonjourcontract
            );
            let register = await bonjourContract.methods
              .ClaimglobalPoolIncome()
              .send({
                from: datas.address,
              });
            if (register && register.status && register.transactionHash) {
              let act_data = {
                address: datas.address,
                userid: datas.userdetails.userid,
                amount: datas.userdetails.globalPoolIncome,
                event: "globalclaim",
                hash: register.transactionHash,
              };
              await addactivity(act_data);
              toastAlert(
                "success",
                "Global Pool Income Claimed Successfully",
                "network"
              );
              setglobalprogress("false");
              window.location.reload();
            } else {
              setglobalprogress("false");
              toastAlert("error", "Failed , try again later", "network");
            }
          } else {
            setglobalprogress("false");
            toastAlert("error", "Insufficient Earnings", "network");
          }
        } else {
          setglobalprogress("false");
          toastAlert("error", "Join First to proceed Further", "network");
          window.location.href = "/join-now";
        }
      } else {
        setglobalprogress("false");
        toastAlert("error", "Please select BSC chain network", "network");
      }
    } catch (err) {
      setglobalprogress("false");
    }
  }

  async function claimRewards(active) {
    if(acceptcondition){
      setwithdrawprogress("true");
      setclaimprogress(active);
      try {
        let datas = await getCurAddr();
        if (
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != "" &&
          localStorage.getItem("account")
        ) {
          if (datas.userdetails && datas.userdetails.isExist) {
            console.log(
              userdetails.availableamt,
              "=datas.minwithdrawvalue======",
              datas.minwithdrawvalue
            );
            if (parseFloat(userdetails.availableamt) > 0) {
              // if (
              //   parseFloat(userdetails.availableamt) / 1e18 >=
              //   parseFloat(datas.minwithdrawvalue).toFixed(2)
              // ) {
                let web3 = new Web3(
                  datas &&
                  datas.provider &&
                  datas.provider != null &&
                  datas.provider != undefined &&
                  datas.provider != ""
                    ? datas.provider
                    : window.ethereum
                );
                var bonjourContract = new web3.eth.Contract(
                  bonjourapi,
                  config.bonjourcontract
                );
                var tokenContract = new web3.eth.Contract(
                  tokenabi,
                  config.bonjourtokencontract
                );
                var busdContract = new web3.eth.Contract(
                  busdabi,
                  config.busdcontract
                );
                let contractbalance = await busdContract.methods.balanceOf(config.bonjourcontract).call();
                let isStatus1 = await bonjourContract.methods.checkIsEnable(datas.address).call();
                //if((isStatus1 == true)||(userdetails.investmentAmount>=5000000000000000000000 && userdetails.referralCount == 0 )||(isNewuser)){
                  //let compare = parseFloat(20000000000000000000000)+(parseFloat(datas.userdetails.earnedAmount)*1e18)
                  //if(parseInt(contractbalance)>=parseFloat(compare)){
                    //if((userdetails.investmentAmount>=5000000000000000000000 && userdetails.referralCount == 0 ) || (isEligible) ||(isNewuser)){
                        //let isclaim = myrole > 1 &&  (!isNewuser) ? true : false ;
                        //if((isclaim && isclaim[0]) ||(userdetails.investmentAmount>=5000000000000000000000 && userdetails.referralCount == 0 ) || isEligible || isNewuser){
                          let tokenbalance = await tokenContract.methods
                            .balanceOf(datas.address)
                            .call();
                          let x = presaledetails ? presaledetails : 0;
                          x = (parseFloat(x) > parseFloat(tokenbalance)) ? parseFloat(tokenbalance): parseFloat(x) * parseFloat(tokenprice);
                          //if (parseFloat(x) > 0) {
                            x =parseFloat(x) > parseFloat(userdetails.availableamt)
                                ? parseFloat(userdetails.availableamt)
                                : x;
                            let register = await bonjourContract.methods
                              .claimOrRetopop(active)
                              .send({
                                from: datas.address,
                              });
                            if (register && register.status && register.transactionHash) {
                              let act_data = {
                                address: datas.address,
                                userid: datas.userdetails.userid,
                                amount: userdetails.availableamt,
                                event: active ? "claim" : "retopup",
                                hash: register.transactionHash,
                                isaccept : acceptcondition
                              };
                              await addactivity(act_data);
                              toastAlert(
                                "success",
                                "Earnings Claimed Successfully",
                                "network"
                              );
                              setwithdrawprogress("false");
                              setclaimprogress("");
                              window.location.reload();
                            } else {
                              setwithdrawprogress("false");
                              setclaimprogress("");
                              toastAlert("error", "Failed , try again later", "network");
                            }
                          // } else {
                          //   setwithdrawprogress("false");
                          //   setclaimprogress("");
                          //   toastAlert(
                          //     "error",
                          //     "You are not participated in Presale",
                          //     "network"
                          //   );
                          // }
                        //  }else{
                        //   setwithdrawprogress("false");
                        //   setclaimprogress("");
                        //   toastAlert("error", "You dont have enough new Volume.", "network");
                        // }
                    // }else{
                    //   setwithdrawprogress("false");
                    //   setclaimprogress("");
                    //   toastAlert("error", "You are not elligible to Claim.", "network");
                    // }
                 // }else{
                 //  setwithdrawprogress("false");
                 //  setclaimprogress("");
                 //  toastAlert("error", "Live testing is going ON, Sorry for the Inconvenience.", "network");
                 // }
                // }else{
                //   setwithdrawprogress("false");
                //   setclaimprogress("");
                //   toastAlert("error", "You need to add 1 referal to enable Claim", "network");
                // }
              // } else {
              //   setwithdrawprogress("false");
              //   setclaimprogress("");
              //   let tst =
              //     "Insufficient Earnings, minimum Amount :" +
              //     parseFloat(datas.minwithdrawvalue).toFixed(2) +
              //     " BUSD";
              //   toastAlert("error", tst, "network");
              // }
            } else {
              setwithdrawprogress("false");
              setclaimprogress("");
              toastAlert("error", "Insufficient Earnings", "network");
            }
          } else {
            setwithdrawprogress("false");
            setclaimprogress("");
            toastAlert("error", "Join First to proceed Further", "network");
            window.location.href = "/join-now";
          }
        } else {
          setwithdrawprogress("false");
          setclaimprogress("");
          toastAlert("error", "Please select BSC chain network", "network");
        }
      } catch (err) {
        setwithdrawprogress("false");
        setclaimprogress("");
        console.log(err, "======err in claim");
      }
    }else{
      toastAlert("error", "Please accept Terms and Condition to proceed Further", "network");
    }
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText =
      config.Front_URL + "join-now/" + localStorage.getItem("account");
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  async function copyToClipboard_1(e) {
    var textField = document.createElement("textarea");
    textField.innerText = localStorage.getItem("account");
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  async function getuserdetails(addr) {
    settreeprogress(true);
    let datas = await getCurAddr();
    let web3call = new Web3(
      datas &&
      datas.provider &&
      datas.provider != null &&
      datas.provider != undefined &&
      datas.provider != ""
        ? datas.provider
        : window.ethereum
    );
    try {
      var bonjourContract = new web3call.eth.Contract(bonjourapi,config.bonjourcontract);
      const multicall = new Multicall({
        web3Instance: web3call,
      });
      var contractCallContext = [
        {
          reference: "details",
          contractAddress: config.bonjourcontract,
          abi: bonjourapi,
          calls: [
            {
              reference: "userprofile",
              methodName: "users",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "referral",
              methodName: "getAllReferralAddress",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "totalparticipant",
              methodName: "totalParticipants",
              methodParameters: [],
            },
            {
              reference: "checkRoiUpto",
              methodName: "checkRoiUpto",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "totalGainAmount",
              methodName: "totalGainAmount",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "checkLevelUpto",
              methodName: "checkLevelUpto",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "checkAvailableUpto",
              methodName: "checkAvailableUpto",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "bonjourtokenPrice",
              methodName: "bonjourtokenPrice",
              methodParameters: [],
            },
            {
              reference: "currentRole",
              methodName: "currentRole",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "checkIsEnable",
              methodName: "checkIsEnable",
              methodParameters: [localStorage.getItem("account")],
            },
            //  {
            //   reference: "getDownIds",
            //   methodName: "getDownIds",
            //   methodParameters: [localStorage.getItem("account")],
            // },
            // {
            //   reference: "checkbeginnereligible",
            //   methodName: "checkbeginnereligible",
            //   methodParameters: [localStorage.getItem("account")],
            // },
            {
              reference: "isNewUser",
              methodName: "isNewUser",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "isClaimEnable",
              methodName: "isClaimEnable",
              methodParameters: [localStorage.getItem("account")],
            },
            {
              reference: "tokenClaim",
              methodName: "tokenClaim",
              methodParameters: [localStorage.getItem("account")],
            },

          ],
        },
      ];
      const results = await multicall.call(contractCallContext);
      var userprofile = await getFormatMulticall(results, "userprofile", 0);
      var downlinenode = await getFormatMulticall(results, "referral", 0);
      //var downidlength = await getFormatMulticall(results, "getDownIds", 0);
      var totalparticipant = await getFormatMulticall(
        results,
        "totalparticipant",
        0
      );
      var totgain = await getFormatMulticall(results, "totalGainAmount", 0);
      var checkRoiUpto = await getFormatMulticall(results, "checkRoiUpto", 0);
      var checkLevelUpto = await getFormatMulticall(
        results,
        "checkLevelUpto",
        0
      );
      var checkAvailableUpto = await getFormatMulticall(
        results,
        "checkAvailableUpto",
        0
      );
      var token_price = await getFormatMulticall(
        results,
        "bonjourtokenPrice",
        0
      );
      var current_Role = await getFormatMulticall(
        results,
        "currentRole",
        0
      );
      var isenabled = await getFormatMulticall(
        results,
        "checkIsEnable",
        0
      );

      var isusernew = await getFormatMulticall(
        results,"isNewUser",0);

      var isClaimEnable = await getFormatMulticall(
        results,"isClaimEnable",0);
      var tokenclaim = await getFormatMulticall(
        results,"tokenClaim",0);

      setairdropstatus(tokenclaim[0]);

      // var checkbeginnereligible = await getFormatMulticall(
      //   results,
      //   "checkbeginnereligible",
      //   0
      // );
      
      setairdropisclaim(isClaimEnable[0]);
      setisNewuser(isusernew[0]);
      setclaimnable(isenabled[0]);
      // let downid_length = await bignumber(downidlength[0]);
      // setdowncount(downid_length);
      let bonjour_current_Role = await bignumber(current_Role[0]);
      setmyrole(bonjour_current_Role);
      // let bStatus1= (checkbeginnereligible && checkbeginnereligible[0] && checkbeginnereligible[0]==true) ? checkbeginnereligible[0]:false;
      // let bStatus = (checkbeginnereligible && checkbeginnereligible[0] && checkbeginnereligible[0]==true && parseFloat(bonjour_current_Role)<2)? checkbeginnereligible[0]:false;
      // console.log(bStatus,'bStatus')
      // setisEligible(bStatus);
      // setisEligible1(bStatus1);


      let bonjour_token_price = await bignumber(token_price[0]);
      settokenprice(bonjour_token_price);
      totalparticipant = await bignumber(totalparticipant[0]);
      setusercount(totalparticipant);
      if (userprofile[0]) {
        let userexist = true;
        let userid = await bignumber(userprofile[1]);
        totgain = await bignumber(totgain[0]);
        let levelIncomeEligible = await bignumber(userprofile[2]);
        let referralid = await bignumber(userprofile[3]);
        let investmentAmount = await bignumber(userprofile[4]);
        let earnedAmount = await bignumber(userprofile[5]);
        let levelIncome = await bignumber(userprofile[6]);
        let globalPoolIncome = await bignumber(userprofile[7]);
        let referralCount = await bignumber(userprofile[8]);
        let referralIncome = await bignumber(userprofile[9]);
        let teamVolume = await bignumber(userprofile[10]);
        let roiamt = await bignumber(checkRoiUpto[0]);
        let levelamt = await bignumber(checkLevelUpto[0]);
        let referupto = await bignumber(userprofile[12]);
        let availableamt = await bignumber(checkAvailableUpto[0]);
        let data_user = {
          userexist: userexist,
          userid: userid,
          levelIncomeEligible: levelIncomeEligible,
          referralid: referralid,
          investmentAmount: investmentAmount,
          earnedAmount: earnedAmount ? earnedAmount : 0,
          levelIncome: levelamt ? levelamt : 0,
          availableamt: availableamt ? availableamt : 0,
          globalPoolIncome: globalPoolIncome,
          referralCount: referralCount,
          referralIncome: referralIncome,
          teamVolume: teamVolume,
          referupto: referupto,
          totalgain: totgain,
          roiearning: roiamt ? roiamt : 0,
          datas: datas,
        };
        setuserdetails(data_user);
        let web3 = new Web3(
          datas &&
          datas.provider &&
          datas.provider != null &&
          datas.provider != undefined &&
          datas.provider != ""
            ? datas.provider
            : window.ethereum
        );
        const icoContract = new web3.eth.Contract(icoabi, config.icocontract);
        let user_details = await icoContract.methods
          .userTokenInfo(datas.address)
          .call();
        let valu =
          user_details && user_details.tokenReceived
            ? user_details.tokenReceived
            : 0;
        setpresaledetails(valu);
        var tokenContract = new web3.eth.Contract(
          tokenabi,
          config.bonjourtokencontract
        );
        let tokenbalance = await tokenContract.methods
          .balanceOf(datas.address)
          .call();
        let x = valu ? valu : 0;
        if (parseFloat(x) > parseFloat(tokenbalance)) {
          x = parseFloat(tokenbalance);
        }
        x = parseFloat(x) * parseFloat(bonjour_token_price);
        if (parseFloat(x) > 0) {
          x =
            parseFloat(x) > parseFloat(availableamt)
              ? parseFloat(availableamt)
              : x;
          setavailamt(x);
        } else {
          setavailamt(0);
        }
        //*************************leg calculation**************//
        if(parseInt(downid_length)>0){
          let leginfo = await bonjourContract.methods.getlegInfo(localStorage.getItem("account")).call();
          setpowdervalue(leginfo[0]);
          setsecondvalue(leginfo[1]);
          setremainingvalue(leginfo[2]);
        }
        //**********************downline1************************//
        let downline1 = [];
        let downline2arr = [];
        let downline3arr = [];
        let downline4arr = [];
        let downline5arr = [];
        let downline2 = [];
        let downline3 = [];
        let downline4 = [];
        if (downlinenode.length > 0) {
          for (let node in downlinenode) {
            var downlineCallContext = [
              {
                reference: "details",
                contractAddress: config.bonjourcontract,
                abi: bonjourapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downlinenode[node]],
                  },
                  {
                    reference: "referral",
                    methodName: "getAllReferralAddress",
                    methodParameters: [downlinenode[node]],
                  },
                ],
              },
            ];
            const _results = await multicall.call(downlineCallContext);
            var _userprofile = await getFormatMulticall(
              _results,
              "userprofile",
              0
            );
            var _downlinenode = await getFormatMulticall(
              _results,
              "referral",
              0
            );
            let _userid = await bignumber(_userprofile[1]);
            let _currlevel = await bignumber(_userprofile[2]);
            let _downline = {
              address: downlinenode[node],
              level: _currlevel,
              userid: _userid,
              downlinenode: _downlinenode,
            };
            downline1.push(_downline);
            downline2arr = downline2arr.concat(_downlinenode);
            if (parseFloat(node) + 1 == downlinenode.length) {
              setdownlinefirst(downline1);
            }
          }
        }
        //**********************downline2************************//
        if (downline2arr.length > 0) {
          for (let node1 in downline2arr) {
            var downlineCallContext1 = [
              {
                reference: "details",
                contractAddress: config.bonjourcontract,
                abi: bonjourapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline2arr[node1]],
                  },
                  {
                    reference: "referral",
                    methodName: "getAllReferralAddress",
                    methodParameters: [downline2arr[node1]],
                  },
                ],
              },
            ];
            const _results1 = await multicall.call(downlineCallContext1);
            var _userprofile1 = await getFormatMulticall(
              _results1,
              "userprofile",
              0
            );
            var _downlinenode1 = await getFormatMulticall(
              _results1,
              "referral",
              0
            );
            let _userid1 = await bignumber(_userprofile1[1]);
            let _currlevel1 = await bignumber(_userprofile1[2]);
            let _downline1 = {
              address: downline2arr[node1],
              level: _currlevel1,
              userid: _userid1,
              downlinenode: _downlinenode1,
            };
            downline2.push(_downline1);
            downline3arr = downline3arr.concat(_downlinenode1);
            if (parseFloat(node1) + 1 == downline2arr.length) {
              setdownlinesecond(downline2);
            }
          }
        }
        //**********************downline3************************//
        if (downline3arr.length > 0) {
          for (let node2 in downline3arr) {
            var downlineCallContext2 = [
              {
                reference: "details",
                contractAddress: config.bonjourcontract,
                abi: bonjourapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline3arr[node2]],
                  },
                  {
                    reference: "referral",
                    methodName: "getAllReferralAddress",
                    methodParameters: [downline3arr[node2]],
                  },
                ],
              },
            ];
            const _results2 = await multicall.call(downlineCallContext2);
            var _userprofile2 = await getFormatMulticall(
              _results2,
              "userprofile",
              0
            );
            var _downlinenode2 = await getFormatMulticall(
              _results2,
              "referral",
              0
            );
            let _userid2 = await bignumber(_userprofile2[1]);
            let _currlevel2 = await bignumber(_userprofile2[2]);
            let _downline2 = {
              address: downline3arr[node2],
              level: _currlevel2,
              userid: _userid2,
              downlinenode: _downlinenode2,
            };
            downline3.push(_downline2);
            downline4arr = downline4arr.concat(_downlinenode2);
            if (parseFloat(node2) + 1 == downline3arr.length) {
              setdownlinethird(downline3);
            }
          }
        }
        //**********************downline4************************//
        if (downline4arr.length > 0) {
          for (let node3 in downline4arr) {
            var downlineCallContext3 = [
              {
                reference: "details",
                contractAddress: config.bonjourcontract,
                abi: bonjourapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline4arr[node3]],
                  },
                  {
                    reference: "referral",
                    methodName: "getAllReferralAddress",
                    methodParameters: [downline4arr[node3]],
                  },
                ],
              },
            ];
            const _results3 = await multicall.call(downlineCallContext3);
            var _userprofile3 = await getFormatMulticall(
              _results3,
              "userprofile",
              0
            );
            var _downlinenode3 = await getFormatMulticall(
              _results3,
              "referral",
              0
            );
            let _userid3 = await bignumber(_userprofile3[1]);
            let _currlevel3 = await bignumber(_userprofile3[2]);
            let _downline3 = {
              address: downline4arr[node3],
              level: _currlevel3,
              userid: _userid3,
              downlinenode: _downlinenode3,
            };
            downline4.push(_downline3);
            downline5arr = downline5arr.concat(_downlinenode3);
            if (parseFloat(node3) + 1 == downline4arr.length) {
              setdownlinefourth(downline4);
            }
          }
        }
        let line1arr = [];
        if (downline1.length > 0) {
          syncEach(
            downline1,
            async function (items, next) {
              if (
                items &&
                items.downlinenode &&
                items.downlinenode.length > 0
              ) {
                let subline = [];
                syncEach(
                  items.downlinenode,
                  async function (subitems, next1) {
                    let IndexVal = downline2.findIndex(
                      (val) => val.address == subitems
                    );
                    if (
                      IndexVal >= 0 &&
                      downline2[IndexVal].downlinenode &&
                      downline2[IndexVal].downlinenode.length > 0
                    ) {
                      let subline2 = [];
                      syncEach(
                        downline2[IndexVal].downlinenode,
                        async function (thir_items, next2) {
                          let IndexVal1 = downline3.findIndex(
                            (val) => val.address == thir_items
                          );
                          if (
                            IndexVal1 >= 0 &&
                            downline3[IndexVal1].downlinenode &&
                            downline3[IndexVal1].downlinenode.length > 0
                          ) {
                            let IndexVal = downline3.findIndex(
                              (val) => val.address == thir_items
                            );
                            let user1 = {
                              name: "ID " + downline3[IndexVal].userid,
                            };
                            subline2.push(user1);
                            next2();
                            // let subline3 = [];
                            // syncEach(downline3[IndexVal1].downlinenode, async function (fourth_items,next3) {
                            //   let Index_Val = downline4.findIndex(val => val.address == fourth_items);
                            //     let user22 = {
                            //         name : "ID "+downline4[Index_Val].userid+" ("+downline4[Index_Val].level+")"
                            //     }
                            //     subline3.push(user22);
                            //     next3();
                            // },function( error , success){
                            //   let mainline = {
                            //       "name": "ID "+downline3[IndexVal1].userid+" ("+downline3[IndexVal1].level+")",
                            //       "children": subline3
                            //     }
                            //     setlevel3(mainline);
                            //     subline2.push(mainline);
                            //   next2();
                            // })
                          } else {
                            let IndexVal = downline3.findIndex(
                              (val) => val.address == thir_items
                            );
                            let user1 = {
                              name: "ID " + downline3[IndexVal].userid,
                            };
                            subline2.push(user1);
                            next2();
                          }
                        },
                        function (error, success) {
                          let mainline = {
                            name: "ID " + downline2[IndexVal].userid,
                            children: subline2,
                          };
                          setlevel2(mainline);
                          subline.push(mainline);
                          next1();
                        }
                      );
                    } else {
                      var IndexValue = downline2.findIndex(
                        (val) => val.address == subitems
                      );
                      let user1 = {
                        name: "ID " + downline2[IndexValue].userid,
                      };
                      subline.push(user1);
                      next1();
                    }
                  },
                  function (errs, t_Items) {
                    let mainline = {
                      name: "ID " + items.userid,
                      children: subline,
                    };
                    setlevel1(mainline);
                    line1arr.push(mainline);
                    next();
                  }
                );
              } else {
                let mainline = {
                  name: "ID " + items.userid,
                  children: [],
                };
                setlevel1(mainline);
                setlevel2([]);
                line1arr.push(mainline);
                next();
              }
            },
            function (err, transformedItems) {
              let mainlinearr = {
                name: "ID " + userid,
                children: line1arr,
              };
              setSelected(mainlinearr);
              setallSelected(mainlinearr);
              settreeprogress(false);
            }
          );
        } else {
          let mainlinearr = {
            name: "ID " + userid,
            children: [],
          };
          setSelected(mainlinearr);
          setallSelected(mainlinearr);
          settreeprogress(false);
        }
      }
    } catch (err) {
      console.log(err, "=====errr");
    }
  }

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  function getFormatMulticall(results, name, pos) {
    try {
      var index =
        results &&
        results.results &&
        results.results.details &&
        results.results.details.callsReturnContext &&
        results.results.details.callsReturnContext.findIndex(
          (val) => val.reference == name
        );
      var returnVal =
        results.results.details.callsReturnContext[index] &&
        results.results.details.callsReturnContext[index].returnValues
          ? results.results.details.callsReturnContext[index].returnValues
          : "";
      return returnVal;
    } catch (err) {
      console.log(err, "==+++++++");
      return "";
    }
  }

  const investamtchange = (e) => {
    setinvestamt(e.target.value);
  };

  async function empty() {}

  async function reinvest() {
    try {
      setprogress("true");
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        if (datas.userdetails && datas.userdetails.isExist) {
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );
          if (investamt && parseFloat(investamt) > 0) {
           // if (parseFloat(investamt) >= parseFloat(datas.minvalue).toFixed(2)) {
              var busdContract = new web3.eth.Contract(
                busdabi,
                config.busdcontract
              );
              var bonjourContract = new web3.eth.Contract(
                bonjourapi,
                config.bonjourcontract
              );
              let contractbalance = await busdContract.methods.balanceOf(config.bonjourcontract).call();
              let isStatus = await bonjourContract.methods.checkIsEnable(datas.address).call();
             // if(isStatus == true || isNewuser){
                //if(parseInt(contractbalance)>20000000000000000000000){
               // if(userdetails && userdetails.earnedAmount && (parseFloat(userdetails.earnedAmount)/1e18) >= parseFloat(userdata.minwithdrawvalue)){
                //if(datas.userdetails.levelIncome>0){
                      //let isclaim = myrole> 1 && (!isNewuser) ? true : false ;
                     // if((isclaim && isclaim[0])|| isNewuser){
                        let invest_amt = parseFloat(investamt) * 1e18;
                        let sendamt = await convert(invest_amt);
                        let allowedamt = await convert(10000000000000000000000000);
                        //if (parseFloat(datas.busdvalue) >= parseFloat(sendamt)) {
                          let approvedval = await busdContract.methods
                            .allowance(datas.address, config.bonjourcontract)
                            .call();
                          console.log(approvedval, "====", sendamt, "====send");
                          var allowance =
                            parseFloat(approvedval) < parseFloat(sendamt)
                              ? await busdContract.methods
                                  .approve(config.bonjourcontract, allowedamt.toString())
                                  .send({
                                    from: datas.address,
                                  })
                              : true;
                          if (allowance) {
                            let val = presaledetails ? presaledetails : 0;
                            let register = await bonjourContract.methods
                              .reInvest(sendamt.toString())
                              .send({
                                from: datas.address,
                              });
                            if (register && register.status && register.transactionHash) {
                              let act_data = {
                                address: datas.address,
                                userid: datas.userdetails.userid,
                                amount: investamt,
                                event: "reinvest",
                                hash: register.transactionHash,
                              };
                              await addactivity(act_data);
                              setprogress("false");
                              toastAlert(
                                "success",
                                "Re-Invested Successfully",
                                "network"
                              );
                              window.location.href = "/dashboard";
                            } else {
                              setprogress("false");
                              toastAlert(
                                "success",
                                "Failed , try again later",
                                "network"
                              );
                            }
                          } else {
                            setprogress("false");
                            toastAlert("success", "Failed , try again later", "network");
                          }
                        // } else {
                        //   setprogress("false");
                        //   toastAlert("error", "Low Busd Balance", "network");
                        // }
                        // }else{
                        //   setprogress("false");
                        //   toastAlert("error", "You need to reach minimum withdraw Value", "network");
                      
                        // }
                       // }else{
                       //    setwithdrawprogress("false");
                       //    setclaimprogress("");
                       //    toastAlert("error", "You dont have enough new Volume.", "network");
                       //  }
                      // }else{
                      //     setwithdrawprogress("false");
                      //     setclaimprogress("");
                      //     toastAlert("error", "You dont have new Volume.", "network");
                      // }
                  // }else{
                  //   setwithdrawprogress("false");
                  //   setclaimprogress("");
                  //   toastAlert("error", "You are not elligible to Reinvest.", "network");
                  // }
                // }else{
                //   setprogress("false");
                //   toastAlert("error", "Live testing is going ON, Sorry for the Inconvenience", "network");
                // }
              // }else{
              //   setprogress("false");
              //   toastAlert("error", "You need to add 1 referal to enable ReInvest", "network");
              // }
            // } else {
            //   setprogress("false");
            //   let alert =
            //     "Invest Amount must be greater than or equal to Minimun invest Amount : " +
            //     parseFloat(datas.minvalue).toFixed(2);
            //   toastAlert("error", alert, "network");
            // }
          } else {
            setprogress("false");
            toastAlert("error", "Enter Valid Invest Amount", "network");
          }
        } else {
          setprogress("false");
          toastAlert("error", "User Not Exist", "network");
        }
      } else {
        setprogress("false");
        toastAlert("error", "Please select BSC chain network", "network");
      }
    } catch (err) {
      setprogress("false");
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar_inner />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel dashboard_panel_green">
            <div className="row">
              <div className="col-md-12">
                <div className="title_flex">
                  <h2 className="inner_title">Dashboard</h2>
                  <div className="wallet_div">
                    <label>Your Wallet</label>
                    <div className="inp_grp">
                      <input
                        type="text"
                        className="form-control primary_inp"
                        defaultValue={userdata.address}
                        disabled="disabled"
                      />
                      <button className="copy_btn" onClick={copyToClipboard_1}>
                        <img
                          src={require("../assets/images/copy_icon.png")}
                          className="copy_icon wallet_icon"
                          alt="Copy"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard_top_wrap">
              <div className="row">
                <div className="col-lg-12 col-xl-3">
                  <div className="row">
                    {/* <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox dash_sidebox_level">
                        <div className="">
                          <img
                            src={parseInt(myrole) < 0 ? require("../assets/images/madel_01.png") :
                               parseInt(myrole)==0 ? require("../assets/images/madel_01.png") :
                               parseInt(myrole)==1 ? require("../assets/images/madel_01.png") :
                               parseInt(myrole)==2 ? require("../assets/images/madel_02.png") :
                               parseInt(myrole)==3 ? require("../assets/images/madel_03.png") :
                               parseInt(myrole)==4 ? require("../assets/images/madel_04.png") :
                               parseInt(myrole)==5 ? require("../assets/images/madel_05.png") :
                               parseInt(myrole)==6 ? require("../assets/images/madel_09.png") :
                               parseInt(myrole)==7 ? require("../assets/images/madel_06.png") :
                               parseInt(myrole)==8 ? require("../assets/images/madel_07.png") :
                               parseInt(myrole)==9 ? require("../assets/images/madel_08.png") :
                               parseInt(myrole)==10 && require("../assets/images/madel_10.png")
                            }
                            className="img-fluid"
                            alt="Icon"
                          />
                        </div>
                        <div className="dash_sidebox_right"> 
                          <h4>{parseInt(myrole) < 0 ? "Beginner" :
                               parseInt(myrole)==0 ? "Beginner" :
                               parseInt(myrole)==1 ? "Beginner" :
                               parseInt(myrole)==2 ? "Bronze" :
                               parseInt(myrole)==3 ? "Silver" :
                               parseInt(myrole)==4 ? "Gold" :
                               parseInt(myrole)==5 ? "Sapphire" :
                               parseInt(myrole)==6 ? "Diamond" :
                               parseInt(myrole)==7 ? "White Diamond" :
                               parseInt(myrole)==8 ? "Blue Diamond" :
                               parseInt(myrole)==9 ? "Black Diamond" :
                               parseInt(myrole)==10 && "Crown Ambassador" 
                          }</h4>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox">
                        <div className="dash_sidebox_left">
                          <img
                            src={require("../assets/images/dash_icon_01.png")}
                            alt="Icon"
                          />
                        </div>
                        <div className="dash_sidebox_right">
                          <h3>My ID</h3>
                          <h4>
                            {userdetails && userdetails.userid
                              ? userdetails.userid
                              : "0000"}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox">
                        <div className="dash_sidebox_left">
                          <img
                            src={require("../assets/images/dash_icon_04.png")}
                            alt="Icon"
                          />
                        </div>
                        <div className="dash_sidebox_right">
                          <h3>My Investment</h3>
                          <h4>
                            {userdetails &&
                            userdetails.investmentAmount &&
                            parseFloat(userdetails.investmentAmount) > 0
                              ? (
                                  parseFloat(userdetails.investmentAmount) /
                                  1e18
                                ).toFixed(2)
                              : 0}{" "}
                            <span className="small-font"> BUSD</span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox">
                        <div className="dash_sidebox_left">
                          <img
                            src={require("../assets/images/dash_icon_02.png")}
                            alt="Icon"
                          />
                        </div>
                        <div className="dash_sidebox_right">
                          <h3>Rate of Return</h3>
                          <h4>
                            {userdetails &&
                            userdetails.roiearning &&
                            parseFloat(userdetails.roiearning) > 0
                              ? (
                                  parseFloat(userdetails.roiearning) / 1e18
                                ).toFixed(2)
                              : 0}{" "}
                            <span className="small-font"> BUSD</span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox">
                        <div className="dash_sidebox_left">
                          <img
                            src={require("../assets/images/dash_icon_04.png")}
                            alt="Icon"
                          />
                        </div>
                        <div className="dash_sidebox_right">
                          <h3>Level Income</h3>
                          <h4>
                            {userdetails &&
                            userdetails.levelIncome &&
                            parseFloat(userdetails.levelIncome) > 0
                              ? (
                                  parseFloat(userdetails.levelIncome) / 1e18
                                ).toFixed(2)
                              : 0}{" "}
                            <span className="small-font"> BUSD</span>
                          </h4>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox">
                        <div className="dash_sidebox_left">
                          <img
                            src={require("../assets/images/dash_icon_04.png")}
                            alt="Icon"
                          />
                        </div>
                        <div className="dash_sidebox_right">
                          <h3>Team Volume</h3>
                          <h4>
                            {userdetails &&
                            userdetails.teamVolume &&
                            parseFloat(userdetails.teamVolume) > 0
                              ? (
                                  parseFloat(userdetails.teamVolume) / 1e18
                                ).toFixed(2)
                              : 0}{" "}
                            <span className="small-font"> BUSD</span>
                          </h4>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox">
                        <div className="dash_sidebox_left">
                          <img
                            src={require("../assets/images/dash_icon_03.png")}
                            alt="Icon"
                          />
                        </div>
                        <div className="dash_sidebox_right">
                          <h3>Direct Referrals</h3>
                          <h4>
                            {userdetails && userdetails.referralCount
                              ? userdetails.referralCount
                              : 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                    {(myrole == 1 || myrole == 0 ) && userdetails.referralCount<15 && airdropisclaim && !airdropstatus &&
                    <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox">
                        <div className="dash_sidebox_left">
                          <img
                            src={require("../assets/images/dash_icon_04.png")}
                            alt="Icon"
                          />
                        </div>
                        <div className="dash_sidebox_right">
                          <h3>Airdrop</h3>
                          <button
                            class="primary_btn"
                            type="button"
                            onClick={
                              airdropprogress == "false"
                                ? () => claimairdropamt()
                                : () => empty()
                            }
                          >
                            {airdropprogress == "false"
                              ? "Claim"
                              : "Processing..,"}
                          </button>
                        </div>
                      </div>
                    </div>
                  }

                   {/* <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox">
                        <div className="dash_sidebox_left">
                          <img
                            src={require("../assets/images/dash_icon_04.png")}
                            alt="Icon"
                          />
                        </div>
                        <div className="dash_sidebox_right">
                          <h3>Global Pool Earnings</h3>
                          <h4>
                            {userdetails &&
                            userdetails.globalPoolIncome &&
                            parseFloat(userdetails.globalPoolIncome) > 0
                              ? (
                                  parseFloat(userdetails.globalPoolIncome) /
                                  1e18
                                ).toFixed(2)
                              : 0}{" "}
                            <span className="small-font"> BUSD</span>
                          </h4>
                          <button
                            class="primary_btn"
                            type="button"
                            onClick={
                              globalprogress == "false"
                                ? () => claimglobalearnamt()
                                : () => empty()
                            }
                          >
                            {globalprogress == "false"
                              ? "Claim"
                              : "Processing..,"}
                          </button>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="dash_sidebox">
                        <div className="dash_sidebox_left">
                          <img src={require("../assets/images/dash_icon_04.png")} alt="Icon" />
                        </div>
                        <div className="dash_sidebox_right">
                          <h3>Capping Amount</h3>
                          <h4>{userdetails && userdetails.referupto && parseFloat(userdetails.referupto)>0?(parseFloat(userdetails.referupto)/1e18).toFixed(2):0}</h4>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-6 col-lg-6 col-xl-12">
                      <div className="primary_box dash_box affiliate_box">
                        <h3>Affiliate Link</h3>
                        <div className="inp_grp">
                          <input
                            type="text"
                            className="form-control primary_inp"
                            defaultValue={
                              config.Front_URL +
                              "join-now/" +
                              localStorage.getItem("account")
                            }
                            disabled="disabled"
                          />
                          <button
                            className="copy_btn"
                            onClick={copyToClipboard}
                          >
                            <img
                              src={require("../assets/images/copy_icon.png")}
                              className="copy_icon wallet_icon"
                              alt="Copy"
                            />
                          </button>
                          <ul className="footer_social_links">
                            <InlineShareButtons
                              config={{
                                alignment: "center",
                                //color: 'social',
                                enabled: true,
                                font_size: 16,
                                language: "en",
                                networks: [
                                  "facebook",
                                  "twitter",
                                  "linkedin",
                                  "telegram",
                                  "whatsapp",
                                ],
                                padding: 12,
                                radius: 100,
                                show_total: false,
                                size: 30,
                                url: `${
                                  config.Front_URL
                                }join-now/${localStorage.getItem("account")}`,
                                description:
                                  "The most innovative 100% Decentralized Matrix Platform",
                                title: "Bonjour",
                                message: "Bonjour",
                                subject: "Bonjour",
                                username: "Bonjour",
                              }}
                            />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-xl-9">
                  <div className="primary_box dash_box pink_bg">
                    <div className="dashbox_middle_top">
                      <div>
                        <h2>{usercount ? usercount : 0}</h2>
                        <h4>Total Participants</h4>
                      </div>
                      <div>
                        <h2>
                          {userdetails &&
                          userdetails.totalgain &&
                          parseFloat(userdetails.totalgain) > 0
                            ? (
                                parseFloat(userdetails.totalgain) / 1e18
                              ).toFixed(2)
                            : 0}{" "}
                          BUSD
                        </h2>
                        <h4>Total Gain Amount</h4>
                      </div>
                      <div>
                        <h2>
                          {userdetails &&
                          userdetails.referralIncome &&
                          parseFloat(userdetails.referralIncome) > 0
                            ? (
                                parseFloat(userdetails.referralIncome) / 1e18
                              ).toFixed(2)
                            : 0}{" "}
                          BUSD
                        </h2>
                        <h4>Referral Earnings</h4>
                      </div>
                    </div>
                  </div>

                  <div className="grey_dash_box primary_box dash_box">
                    <div className="grey_dash_box_div">
                      <div>
                        <h4>Your Wallet Balance</h4>
                        <h2>
                          <span>
                            {userdata &&
                            userdata.busdvalue &&
                            parseFloat(userdata.busdvalue) > 0
                              ? (parseFloat(userdata.busdvalue) / 1e18).toFixed(
                                  2
                                )
                              : 0}
                          </span>{" "}
                          <span className="small-font"> BUSD</span>
                        </h2>
                      </div>
                      <div>
                        <h4>Your Earned Amount</h4>
                        <h2>
                          <span>
                            {userdetails &&
                            userdetails.earnedAmount &&
                            parseFloat(userdetails.earnedAmount) > 0
                              ? (
                                  (parseFloat(userdetails.earnedAmount) +
                                    parseFloat(userdetails.roiearning) +
                                    parseFloat(userdetails.levelIncome)) /
                                  1e18
                                ).toFixed(2)
                              : userdetails.roiearning
                              ? (
                                  (parseFloat(0) +
                                    parseFloat(userdetails.roiearning) +
                                    parseFloat(userdetails.levelIncome)) /
                                  1e18
                                ).toFixed(2)
                              : parseFloat(userdetails.levelIncome) > 0
                              ? (
                                  (parseFloat(0) +
                                    parseFloat(0) +
                                    parseFloat(userdetails.levelIncome)) /
                                  1e18
                                ).toFixed(2)
                              : 0}
                          </span>{" "}
                          <span className="small-font"> BUSD</span>
                        </h2>
                      </div>
                     <div className="btnflx"> 
                     {((userdetails.investmentAmount>=5000000000000000000000 && userdetails.referralCount == 0) || (isEligible) || (isNewuser)) &&
                        <button className="primary_btn" data-bs-toggle="modal" data-bs-target="#claim_modal"><img src={require("../assets/images/claim_icon.png")} className='img-fluid wallet_icon' alt='Icon' />Claim</button>
                      }
                      {isEligible1 &&
                       <button className="primary_btn" data-bs-toggle="modal" data-bs-target="#wallet_modal"><img src={require("../assets/images/reinvest_icon.png")} className='img-fluid wallet_icon' alt='Icon' />Reinvest</button>
                      }
                      </div>
                    </div>
                  </div>

                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <div className="inp_grp search_div">
                        <input
                          type="text"
                          className="form-control primary_inp"
                          placeholder="Search by id"
                          onChange={idchange}
                          value={searchid}
                        />
                        <button
                          type="button"
                          onClick={() => handleClick("", "", searchid)}
                        >
                          Search
                        </button>
                      </div>
                      <div>
                        {!treeprogress ? (
                          <AnimatedTree
                            data={selected}
                            height={1000}
                            width={800}
                            gProps={{ className: "node", onClick: handleClick }}
                            nodeShape="image"
                            nodeProps={{
                              href: config.Front_URL + "images/tree_icon.png",
                              width: "13",
                              height: "21",
                            }}
                            margins={{
                              top: 50,
                              bottom: 50,
                              left: 80,
                              right: 80,
                            }}
                            textProps={{ dx: -16, dy: 25 }}
                          />
                        ) : (
                          <div>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <center>
                              <p className="text-danger mb-1">
                                Processing....,Please Wait
                              </p>
                            </center>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {parseInt(myrole)>1 &&
                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Leg Volume</h2>
                      <div className='plan_income_table_div'>
                        <div className='table-responsive'>
                          <table className='table plan_income_table mt-0'>
                            <thead>
                              <tr>
                                <th>Leg</th>
                                <th>Volume</th>
                              </tr>                                
                            </thead>
                            {!tableload ? 
                              <tbody>
                                  <tr>
                                    <th>Power Leg</th>
                                    <td>{(parseFloat(powdervalue)/1e18).toFixed(4)}</td>
                                  </tr>
                                  <tr>
                                    <th>Second Power Leg</th>
                                    <td>{(parseFloat(secondvalue)/1e18).toFixed(4)}</td>
                                  </tr>
                                  <tr>
                                    <th>Remaining Leg's</th>
                                    <td>{(parseFloat(remainingvalue)/1e18).toFixed(4)}</td>
                                  </tr>
                              </tbody>
                              :
                              ""
                            }
                          </table>
                        </div>
                      </div>
                    </div>
                </div>
              }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div
        className="modal fade primary_modal"
        id="wallet_modal"
        tabIndex={-1}
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="walletModalLabel">
                Re-Invest
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label for="exampleFormControlInput1" class="form-label">
                Invest Amount
              </label>
              <input
                type="Number"
                class="form-control primary_inp"
                id="exampleFormControlInput1"
                placeholder="Enter Invest Amount"
                value={investamt}
                onChange={investamtchange}
                min={parseFloat(userdata.minvalue).toFixed(2)}
                max={parseFloat(userdata.maxvalue).toFixed(2)}
              />
              <div className="mt-3">
                <button
                  className="primary_btn"
                  type="button"
                  onClick={
                    progress == "false" ? () => reinvest() : () => empty()
                  }
                >
                  {progress == "false" ? "Submit" : "Processing..Please Wait.."}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade primary_modal"
        id="claim_modal"
        tabIndex={-1}
        aria-labelledby="claimModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="claimModalLabel">
                Claim
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label for="exampleFormControlInput1" class="form-label">
                {presaledetails && presaledetails > 0
                  ? "Purchased from Presale : " +
                    parseFloat(presaledetails / 1e18).toFixed(2) +
                    " Bonjour (" +
                    (
                      parseFloat(presaledetails / 1e18) * parseFloat(tokenprice)
                    ).toFixed(2) +
                    " BUSD)"
                  : "Purchased from Presale : 0 Bonjour (0 BUSD)"}
              </label>
              <label for="exampleFormControlInput1" class="form-label">
                {userdata && userdata.bonjourbalance
                  ? "Bonjour Wallet Balance : " +
                    (parseFloat(userdata.bonjourbalance) / 1e18).toFixed(2) +
                    " Bonjour ( " +
                    (
                      parseFloat(userdata.bonjourbalance / 1e18) *
                      parseFloat(tokenprice)
                    ).toFixed(2) +
                    " BUSD )"
                  : "Bonjour Wallet Balance : 0 Bonjour  (0 BUSD)"}
              </label>
              <label for="exampleFormControlInput1" class="form-label">
                {userdata && userdata.minwithdrawvalue
                  ? "Minimum Withdraw Amount : " +
                    parseFloat(userdata.minwithdrawvalue).toFixed(2) +
                    " BUSD"
                  : "Minimum Withdraw Amount :" + 0 + " BUSD"}
              </label>
              <label for="exampleFormControlInput1" class="form-label">
                {userdetails &&
                userdetails.availableamt &&
                parseFloat(userdetails.availableamt) > 0
                  ? "Your Actual Claim Amount : " +
                    (parseFloat(userdetails.availableamt) / 1e18).toFixed(2) +
                    " BUSD"
                  : "Your Actual Claim Amount :  0 BUSD"}
              </label>
              <label for="exampleFormControlInput1" class="form-label">
                {availamt && parseFloat(availamt) > 0
                  ? "Your Available Claim Amount : " +
                    (parseFloat(availamt) / 1e18).toFixed(2) +
                    " BUSD"
                  : "Your Available Claim Amount :  0 BUSD"}
              </label>
              {!acceptstatus &&
                 <div class="form-check form_chk">
                  <input class="form-check-input" type="checkbox"  id="flexCheckChecked" onChange={hadlechange} />
                  <label class="form-check-label" for="flexCheckChecked">
                  Accept the 
                   <a href="/terms" class="text-dark" target="_blank" > terms and condition</a>
                  </label>
                </div>
              }
              <div className="mt-3">
                <button
                  className="primary_btn"
                  type="button"
                  onClick={
                    withdrawprogress == "false"
                      ? () => claimRewards(true)
                      : () => empty()
                  }
                >
                  {claimprogress ? "Processing..Please Wait.." : "Claim"}
                </button>
                {/* <button className="primary_btn" type="button" onClick={withdrawprogress=="false"?()=>claimRewards(false):()=>empty()}>{claimprogress!=true && withdrawprogress=="true"? "Processing..Please Wait..":"Re-Topup"}</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
