import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import Web3 from "web3";
import {
  getCurAddr,
  updatereferral,
  gettotaluser,
  getlasttransaction,
  checkview,
  get_view_count,
} from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import config from "../config/config";
import * as $ from "jquery";
import * as bootstrap from "bootstrap";
import bonjourapi from "../ABI/bonjourABI"
import axios from "axios";
import Countdown, { zeroPad } from "react-countdown";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
//import video from '../assets/images/autopool.mp4'

//import bootstrap from "bootstrap";

import { COLORS, PARTICLE_COUNT } from "./canvas/config";
//import { Particle } from "./canvas/particle";
// import { ShapeMap } from "./canvas/shapemap";
import { Vector2D } from "./canvas/vector";
import Aboveinfo from "../components/aboveinfo.js";
//import 'bootstrap/dist/css/bootstrap.css';
//import { Modal } from 'bootstrap';

// import DarkHeroStyles from "./canvas/index";
// import tsParticles from "react-tsparticles";
// import Particles from "react-tsparticles";
// import { useCallback } from "react";
// import { loadFull } from "tsparticles";
// import { tsParticles } from "tsparticles-engine";

import { type WalletClient, useWalletClient } from 'wagmi'


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

document.addEventListener("DOMContentLoaded", function (event) {
  setTimeout(function () {
    console.log("**");
    var myModalEl = document.getElementById("autopool_info");
    if (myModalEl && myModalEl != null && myModalEl != undefined) {
      var myModal = new bootstrap.Modal(myModalEl, { keyboard: false });
      myModal.show();
    }
  }, 1000);
});

// type Force = (pos: Vector2D) => Vector2D;

// function getForces(center: Vector2D, minDim: number): Force[] {
//   const factor = 1; //950 / minDim;

//   return [
//     (p) => {
//       const dir = p.sub(center);
//       const mag = dir.mag();
//       dir.mult_mut(7000 / factor / mag / mag);
//       return dir;
//     },
//     (p) => {
//       const dir = center.sub(p);
//       dir.mult_mut(20 / factor / dir.mag());
//       return dir;
//     },
//     (p) => {
//       const dir = center.sub(p);
//       const mag = dir.mag();
//       return new Vector2D(
//         ((1000 / factor) * dir.y) / mag / mag,
//         ((1000 / factor) * -dir.x) / mag / mag
//       );
//     },
//   ];
// }

export default function Home(props) {
  var { id } = useParams();
  // Countdown Timer
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hrs</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Mins</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">Secs</span>
        </span>
      </div>
    );
  };

  const [data, setdata] = useState({});
  const [referaladdress, setreferaladdress] = useState("");
  const [latestrec, setlatestrec] = useState([]);
  const [viewcounts, setviewcounts] = useState(0);
  const [totaluser, settotaluser] = useState(0);
  const [payoutamt, setpayoutamt] = useState(0);

  var recent_activity_settings = {
    centerMode: true,
    centerPadding: "1px",
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var begineer_settings = {
    centerMode: true,
    centerPadding: "1px",
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  
  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })

  // Animation Ends
  useEffect(() => {
    getdetails();
  }, [walletClient]);

  let idy = 0;

  async function checking(){
    let _data = await getCurAddr();
    if (
      _data &&
      _data.address &&
      _data.address != undefined &&
      _data.address != null &&
      _data.address != "" &&
      localStorage.getItem("account")
    ) {

      let web3 =  new Web3(_data && _data.provider && _data.provider!=null && _data.provider!=undefined && _data.provider!=""?_data.provider:window.ethereum);
      var bonjourContract = new web3.eth.Contract(bonjourapi, config.bonjourcontract);
      let userdetails = await bonjourContract.methods.getlegInfo("0xD701668C6Eb350297864b85e620dED7e4E59c906").call();
      console.log(userdetails,"=====leginfoo")
      console.log(userdetails[0],"=====leginfoo")
      console.log(userdetails[1],"=====leginfoo")
      console.log(userdetails[2],"=====leginfoo")
    // if(userdetails.levelIncome>0 || userdetails.levelIncome==0  ){
    //               console.log("isclinbefore")
    //                 let downval = await bonjourContract.methods.getDownIds("0x8EC98Cb565c1942D72F794567dBF526F03d2c1a4").call();
    //                 if(downval>=3 || userdetails.levelIncome==0 ){
    //                   let isclaim = downval>=3 ? await bonjourContract.methods.checkdecending("0x8EC98Cb565c1942D72F794567dBF526F03d2c1a4",downval).call() : false;
    //                   console.log(isclaim,"===isclain")
    //                   if(isclaim || userdetails.levelIncome==0 ){
    //                     console.log(isclaim,"====isclaim",downval)
    //                   }else{
                        
    //                     toastAlert("error", "You dont have enough new Volume.", "network");
    //                   }
    //                 }else{
                        
    //                     toastAlert("error", "You dont have new Volume.", "network");
    //                 }
    //               }else{
                   
    //                 toastAlert("error", "You are not elligible to Claim.", "network");
    //               }
                }
  }

  async function checkreferal(_data) {
    console.log(id, "-====inhome", _data);
    if (id && id != null && id != undefined && id != "" && idy == 0) {
      if (id.toLowerCase() != _data.address.toLowerCase()) {
        idy = 1;
        //let web3 =  new Web3(_data && _data.provider && _data.provider!=null && _data.provider!=undefined && _data.provider!=""?_data.provider:window.ethereum);
        //if(web3.utils.isAddress(id)){
        // var bonjourContract = new web3.eth.Contract(bonjourapi, config.bonjourcontract);
        // let userdetails = await bonjourContract.methods.users(id).call();
        // if(userdetails.isExist){
        //   let minedet = await bonjourContract.methods.users(_data.address).call();
        //   if(!minedet.isExist){
        console.log("hiiiiii");
        setreferaladdress(id);
        let inputdata = {
          useraddress: _data.address,
          referral: id,
        };
        console.log("iiiiih");
        await updatereferral(inputdata);
        // }
        // }
        //}
      }
    }
  }

  // async function getviewcount() {
  //   let rect = await get_view_count();
  //   if(rect && rect.data && rect.data.data){
  //     setviewcounts((rect.data.data).length);
  //   }
  // }

  async function getdetails() {
    let rects = await gettotaluser();
    if (rects && rects.data && rects.data.data) {
      settotaluser(rects.data.data.count);
    }
    if (rects && rects.data && rects.data.data) {
      setpayoutamt(rects.data.data.payout);
    }

    let rect = await getlasttransaction();
    if (rect && rect.data && rect.data.data) {
      console.log(rect.data.data, "====rect.data.data");
      setlatestrec(rect.data.data);
    }
    let datas = await getCurAddr();
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" &&
      localStorage.getItem("account")
    ) {
      setdata(datas);
      if (datas.userdetails && datas.userdetails.isExist) {
        //window.location.href="/dashboard"
      } else {
        await checkreferal(datas);
      }
    }
  }

  // async function getIpdetails(){
  //   const res = await axios.get("https://api.ipify.org/?format=json");
  //   if(res && res.data && res.data.ip){
  //     let input = {
  //       "address" : res.data.ip
  //     }
  //     checkview(input);
  //   }
  // }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />

      <section className="out_header">
        <div className="page_header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="head_title">
                  <h1>
                    The Highly anticipated <span>decentralized</span>{" "}
                    <span>matrix platform</span>
                  </h1>
                  <h5>
                    Maximize your earnings by investing a minimum amount.
                    Explore new opportunities with Bonjour.
                  </h5>
                </div>
                {/*<button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#autopool_info"
                  >
                    popup
                  </button>*/}
                <div className="infobox">
                  <center>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="box">
                          <img
                            src={require("../assets/images/b2.png")}
                            className="img-fluid"
                            alt="Banner"
                          />
                          <h5>
                            {(
                              parseFloat(590000) + parseFloat(payoutamt)
                            ).toFixed(2)}{" "}
                            BUSD
                          </h5>
                          <p>Total Pay Out</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="box box_purple">
                          <img
                            src={require("../assets/images/b3.png")}
                            className="img-fluid"
                            alt="Banner"
                          />
                          <h5>{totaluser ? totaluser : 0}</h5>
                          <p>Total Participants</p>
                        </div>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
               <div className="banner_img">
                  <img
                    src={require("../assets/images/banner_gif.gif")}
                    className="img-fluid banner_mobile"
                    alt="Icon"
                  />
                  <div className="globe">
                    <div className="sphere"></div>
                    <div className="outer_shadow"></div>
                    <div className="worldmap">
                      <div className="worldmap_back"></div>
                      <div className="worldmap_front"></div>
                    </div>
                    <div className="inner_shadow"></div>
                  </div>
                </div> 
               {/* <div className="autopool-video">
                  <video width="750" height="500" className="img-fluid" controls >
                    <source src={video} type="video/mp4"/>
                  </video>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="main_wrapper">
        {/* Community Section */}
        <section className="features_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small text-center">
                    Global community
                  </h3>
                </ScrollAnimation>
              </div>
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h2 className="main_title text-center">
                    {" "}
                    A worldwide enterprise
                  </h2>
                </ScrollAnimation>
                <div className="row features_section_row">
                  <div className="col-md-6 col-lg-4 d-flex">
                    <ScrollAnimation animateIn="slideInUp">
                      <div className="features_box">
                        <img
                          src={require("../assets/images/icon_01.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h4>Low-risk investment</h4>
                        <p className="inner_para">
                          Maximize your potential income with minimal
                          investment. Bonjour is a risk-free networking portal
                          that enables you to earn significantly through a
                          global community.
                        </p>
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-md-6 col-lg-4 d-flex">
                    <ScrollAnimation animateIn="slideInUp">
                      <div className="features_box">
                        <img
                          src={require("../assets/images/icon_02.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h4>Secure Blockchain</h4>
                        <p className="inner_para">
                          Bonjour operates on the highly secure BSC (Binance
                          Smart Chain) blockchain. It is fully decentralized and
                          offers robust data and investment security.
                        </p>
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-md-6 col-lg-4 d-flex">
                    <ScrollAnimation animateIn="slideInUp">
                      <div className="features_box">
                        <img
                          src={require("../assets/images/icon_03.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h4>Unrestricted access</h4>
                        <p className="inner_para">
                          There are no barriers to joining Bonjour. You can
                          easily start earning significantly by simply accessing
                          the platform with a minimum $10 investment.
                        </p>
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg_img">
          <section className="mar_top_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="slideInUp">
                    <p className="inner_para text-center">
                      Bonjour's members can earn significant sums of money
                      through simple referrals. There is no limit to the number
                      of referrals. You can invest in Bonjour from a minimum of
                      $10. <br /> Bonjour offers three types of income streams:
                      referral income, reward points, level income, all of which are associated with 7 levels.
                    </p>
                  </ScrollAnimation>
                  {/* <ScrollAnimation animateIn="slideInUp">
                    <div className="counter_panel">
                      <div>
                      <img src={require("../assets/images/icon_04.png")} className='img-fluid' alt='Icon' />
                      <h3><CountUp end={49150} /></h3>
                      <h6>All Participants</h6>
                    </div>
                    <div>
                      <img src={require("../assets/images/icon_05.png")} className='img-fluid' alt='Icon' />
                      <h3><CountUp end={50} /></h3>
                      <h6>Total Earned BUSD</h6>
                    </div>
                    <div>
                      <img src={require("../assets/images/icon_06.png")} className='img-fluid' alt='Icon' />
                      <h3><CountUp end={62250} /></h3>
                      <h6>Total Earned USD</h6>
                    </div>
                    </div>
                  </ScrollAnimation> */}
                </div>
              </div>
            </div>
          </section>

          {/* <section className="level_table_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">                  
                </div>
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="slideInUp">
                    <h2 className="main_title text-center">
                      Introducing levels in Membership
                    </h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="slideInUp">
                    <div className="level_box_wrap">                   

                      <div className="level_box_div level_box_one">
                        <h1>Beginner</h1>
                        <img
                          src={require("../assets/images/madel_01.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$25 - $75</h2>
                        <p>Team Value</p>
                        <Slider {...begineer_settings}>
                          <h3>0 - 3000<br />25$</h3>
                          <h3>3001 - 10000<br />30$</h3>
                          <h3>10001 - 25000<br />40$</h3>
                          <h3>25001 - 50000<br />50$</h3>
                          <h3>50001 - 75000<br />75$</h3>
                        </Slider>
                      </div>

                      <div className="level_box_div level_box_two">
                        <h1>Bronze</h1>
                        <img
                          src={require("../assets/images/madel_02.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$150</h2>
                        <p>Team Value</p>
                        <h3>$75k - $149k</h3>
                      </div>

                      <div className="level_box_div level_box_three">
                        <h1>Silver</h1>
                        <img
                          src={require("../assets/images/madel_03.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$200</h2>
                        <p>Team Value</p>
                        <h3>$150k - $299k</h3>
                      </div>

                      <div className="level_box_div level_box_four">
                        <h1>Gold</h1>
                        <img
                          src={require("../assets/images/madel_04.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$250</h2>
                        <p>Team Value</p>
                        <h3>$300k - $599k</h3>
                      </div>

                      <div className="level_box_div level_box_five">
                        <h1>Sapphire</h1>
                        <img
                          src={require("../assets/images/madel_05.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$500</h2>
                        <p>Team Value</p>
                        <h3>$600k - $1199k</h3>
                      </div>

                      <div className="level_box_div level_box_nine">
                        <h1>Diamond</h1>
                        <img
                          src={require("../assets/images/madel_09.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$750</h2>
                        <p>Team Value</p>
                        <h3>$1200k - $2399k</h3>
                      </div>

                      <div className="level_box_div level_box_six">
                        <h1>White Diamond</h1>
                        <img
                          src={require("../assets/images/madel_06.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$1000</h2>
                        <p>Team Value</p>
                        <h3>$2400k - $4799k</h3>
                      </div>

                      <div className="level_box_div level_box_seven">
                        <h1>Blue Diamond</h1>
                        <img
                          src={require("../assets/images/madel_07.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$1500</h2>
                        <p>Team Value</p>
                        <h3>$4800k -  $9599k</h3>
                      </div>

                      <div className="level_box_div level_box_eight">
                        <h1>Black Diamond</h1>
                        <img
                          src={require("../assets/images/madel_08.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$2000</h2>
                        <p>Team Value</p>
                        <h3>$9600k - $19199k</h3>
                      </div>

                      

                      <div className="level_box_div level_box_ten">
                        <h1>Crown Ambassador</h1>
                        <img
                          src={require("../assets/images/madel_10.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <p>Daily Withdrawals</p>
                        <h2>$2500</h2>
                        <p>Team Value</p>
                        <h3>$19200k - $38400k</h3>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </section> */}

          {/* Plan Income Section */}
          <section className="income_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="slideInUp">
                    <h3 className="main_title_small text-center">
                      Referral Income
                    </h3>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="slideInUp">
                    <h2 className="main_title text-center">
                      Explanation of level income
                    </h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="slideInUp">
                    <p className="inner_para text-center">
                      All data is stored on the blockchain and is available for
                      public verification.
                    </p>
                    <p className="inner_para text-center">
                      Contract address:{" "}
                      <a
                        href={
                          config.bscurl + "address/" + config.bonjourcontract
                        }
                        target="_blank"
                      >
                        {config.bonjourcontract}
                      </a>
                    </p>
                  </ScrollAnimation>
                  <div className="row mt-5">
                    <div className="col-md-12 col-lg-6 text-center">
                      <ScrollAnimation animateIn="slideInUp">
                        <h5>Level Income (0.75%)</h5>
                        <div className="plan_income_table_div">
                          <div className="table-responsive">
                            <table className="table plan_income_table">
                              <thead>
                                <tr>
                                  <th>Level</th>
                                  <th>Referral</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>0.2%</td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>0.15</td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>0.1%</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>0.1%</td>
                                </tr>
                                <tr>
                                  <td>5</td>
                                  <td>0.1%</td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>0.5%</td>
                                </tr>
                                <tr>
                                  <td>7</td>
                                  <td>0.5%</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </ScrollAnimation>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <ScrollAnimation animateIn="slideInUp">
                        {/*<h5>Global Pool</h5>
                        <ul className="global_pool_list">
                          <li>
                            The total investment from the team exceeds{" "}
                            <span>$120000</span>.
                          </li>
                        </ul>*/}

                        <h5>Restriction of level Income</h5>
                        <ul className="global_pool_list">
                          <li>
                            To qualify for level 1 income, the user must have a
                            minimum of 5 direct referrals.
                          </li>
                          <li>
                            To qualify for levels 2, 3, and 4 income, the user
                            must have a minimum of 15 direct referrals.
                          </li>
                          <li>
                            To qualify for levels 5, 6, and 7 income, the user's
                            direct referrals must each have a minimum of 3 child
                            referrals who have each referred at least 15
                            directs.
                          </li>
                        </ul>
                      </ScrollAnimation>
                    </div>
                  </div>
                  <h4 className="text-center">
                     It's a crowd Funding business, Based on this Business you will get variable reward points.
                  </h4>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="platform_activity">
          <div className="container">
            <div className="head">
              <ScrollAnimation animateIn="zoomIn">
                <h4 className="main_title_small text-center">PLATFORM</h4>
                <h2 className="main_title text-center"> Recent Activity</h2>
              </ScrollAnimation>
            </div>

            <div className="recent_activity_slider">
              <Slider {...recent_activity_settings}>
                {latestrec &&
                  latestrec.length > 0 &&
                  latestrec.map((item) => {
                    return (
                      <div>
                        <div className="recent_activity_panel">
                          <div className="slider_img">
                            {item && item.event == "regUser" ? (
                              <img
                                src={require("../assets/images/slider_user.png")}
                                className="img-fluid"
                                alt="img"
                              />
                            ) : (
                              <img
                                src={require("../assets/images/slider_wallet.png")}
                                className="img-fluid"
                                alt="img"
                              />
                            )}
                          </div>
                          <div className="activity_type">
                            {(item.event == "claimOrRetopop" ||
                              item.event == "reInvest") && (
                              <div>
                                <p className="green">+ BNB </p>
                              </div>
                            )}

                            {item && item.event == "regUser" ? (
                              <div>
                                <p className="violet">New user joined</p>
                              </div>
                            ) : item && item.event == "reInvest" ? (
                              <div>
                                <p className="violet">Re-Invest</p>
                              </div>
                            ) : (
                              <div>
                                <p className="violet">Claim</p>
                              </div>
                            )}
                          </div>
                          <div className="activity_id">
                            <div>ID {item.userid}</div>
                          </div>
                          <div className="rightbtn">
                            <p>
                              <span>
                                {" "}
                                <a
                                  href={config.bscurl + "tx/" + item.trx}
                                  target="_blank"
                                >
                                  <i className="bi bi-box-arrow-up-right me-2"></i>
                                </a>
                              </span>
                              <span>
                                {" "}
                                {item && item.hours > 0
                                  ? item.hours
                                  : item.min}{" "}
                                {item && item.hours > 0 ? " Hours" : " Min"}{" "}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>

            {/* <ScrollAnimation animateIn='zoomIn'> */}
            {/* <Scrollbars style={{ width: "100%", height: 450 }}>
              <div className="activity_scroll">
                {latestrec &&
                  latestrec.length > 0 &&
                  latestrec.map((item) => {
                    return (
                      <div className="activity_box_flx">
                        <div>
                          <div className="flxbox">
                            <div>
                              <img
                                src={require("../assets/images/bonjur_logo.png")}
                                className="img-fluid"
                                alt="img"
                              />
                            </div>
                            <div>
                              {item && item.event == "regUser" ? (
                                <img
                                  src={require("../assets/images/user_prf.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              ) : (
                                <img
                                  src={require("../assets/images/wallet.png")}
                                  className="img-fluid"
                                  alt="img"
                                />
                              )}
                            </div>
                            {item && item.event == "regUser" ? (
                              <div>
                                <p className="violet">New user joined</p>
                              </div>
                            ) : item && item.event == "reInvest" ? (
                              <div>
                                <p className="violet">Re-Invest</p>
                              </div>
                            ) : (
                              <div>
                                <p className="violet">Claim</p>
                              </div>
                            )}
                            <div>
                              <div className="btn">ID {item.userid}</div>
                            </div>
                            {(item.event == "claimOrRetopop" ||
                              item.event == "reInvest") && (
                              <div>
                                <p className="green">+ BNB </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="rightbtn">
                          <p>
                            <span>
                              {" "}
                              <a
                                href={config.bscurl + "tx/" + item.trx}
                                target="_blank"
                              >
                                <img
                                  src={require("../assets/images/arrow.png")}
                                  className="img-fluid me-2"
                                  alt="img"
                                />
                              </a>
                            </span>
                            <span>
                              {" "}
                              {item && item.hours > 0
                                ? item.hours
                                : item.min}{" "}
                              {item && item.hours > 0 ? " Hours" : " Min"}{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Scrollbars> */}
          </div>
        </section>

        {/* How It Works Section */}
        <section className="how_works_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small text-center">
                    Partners Payout
                  </h3>
                </ScrollAnimation>
              </div>
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h2 className="main_title text-center">
                    Explanation of partner income
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" delay="300">
                  <p className="inner_para  text-center">
                    The first user or parent will access the platform with an
                    initial amount of <span className="txt_bold">$10</span>
                  </p>
                </ScrollAnimation>
                {/* <ScrollAnimation animateIn="slideInUp" delay="600">
                  <p className="inner_para text-center">
                    Initial amount + referral income (BUSD) = $50 + 7%
                  </p>
                </ScrollAnimation> */}
                <ScrollAnimation animateIn="slideInUp">
                  <div className="box">
                    {/* <ScrollAnimation animateIn="slideInUp">
                      <div className="text-center">
                        <h3>10 BUSD TO JOIN</h3>
                      </div>
                    </ScrollAnimation> */}
                    <ScrollAnimation animateIn="slideInUp">
                      <div className="text-center">
                        <img
                          src={require("../assets/images/tree_structure.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                      </div>
                    </ScrollAnimation>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* faq Section */}
        <section className="faq_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small">Explain our Platform</h3>
                </ScrollAnimation>
              </div>
              <div className="col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h2 className="main_title">FAQ</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp">
                  <p className="inner_para">
                    You can send email with your questions and we'll give your
                    answer
                  </p>
                </ScrollAnimation>
                <div className="row">
                  <div className="col-md-12 col-lg-10 mx-auto">
                    <div className="accordion" id="faq_accodian">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What is Bonjour?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#faq_accodian"
                        >
                          <div className="accordion-body">
                            <p>
                              Bonjour is a decentralized networking platform
                              that operates on the BSC blockchain. It offers
                              easy earning opportunities for members through
                              referrals.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            {" "}
                            How many levels of total income streams are there
                            with Bonjour?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#faq_accodian"
                        >
                          <div className="accordion-body">
                            <p>
                              Bonjour offers 7 levels of income, including 3
                              major streams: level income,
                              direct referral income, and reward points.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            {" "}
                            What is the minimum investment required to join
                            Bonjour?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#faq_accodian"
                        >
                          <div className="accordion-body">
                            <p>
                              Bonjour has a minimum investment requirement of
                              $10.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            {" "}
                            How secure is my investment with Bonjour?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#faq_accodian"
                        >
                          <div className="accordion-body">
                            <p>
                              Your investment with Bonjour is 100% secure and
                              highly profitable. Additionally, Bonjour is a
                              highly secure BSC-based DEX Matrix platform,
                              offering multi-layer security protection for your
                              invested funds.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-center">
              <button className="primary_btn">READ MORE</button>
            </div> */}
          </div>
        </section>
        <Footer />
      </div>
      {/* Bonjour Presale Modal */}
      {/* <div className="modal fade primary_modal" id="presale_info">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <h2>Token Presale Starts Soon</h2>
              <img
                src={require("../assets/images/bnjr_token.png")}
                className="img-fluid wallet_icon"
                alt="Icon"
              />
              <h2>Bonjour Token</h2>
              <div className="text-center mt-4 mb-3">
                <button className="primary_btn">
                  <a href="https://presale.webonjour.com/" target="_blank">
                    More Info
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* latest pop up offer valid three days */}
      {/* <div className="modal fade autopool_modal imgpopup" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <img
                src={require("../assets/images/popup_v2.png")}
                className="img-fluid pc"
                alt="Icon"
              />
                  <img
                src={require("../assets/images/popup_v3.png")}
                className="img-fluid mobile"
                alt="Icon"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* Bonjour maintenance info Modal */}
      {/* <div className="modal fade autopool_modal" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <img
                src={require("../assets/images/logo_large.png")}
                className="img-fluid"
                alt="Icon"
              />
              <div className="bonjour_modal_full bonjour_modal_full_chg">
                <div>
                  <p className="popup_para blw">Dear Bonjour Community!!</p>
                <p className="popup_para"> Important Update…
                </p>
                <p className="popup_para"> Kindly do Claim based on your Previous Claim timing
                </p>
                <p className="popup_para">Regards<br></br><span>Bonjour Team</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Bonjour maintenance info Modal */}
      {/*<div className="modal fade autopool_modal" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <img
                src={require("../assets/images/logo_large.png")}
                className="img-fluid"
                alt="Icon"
              />
              <div className="bonjour_modal_full bonjour_modal_full_chg">
                <div className="bonjour_presale_modalinfo">
                 
                  <p className="popup_para blw">
                  Dear Users 
                  </p>
                  <p className="popup_para">
                  This is to inform you that Our site will be Under Maintenance for Condition Upgradation from 7.30.A.M to 8.00.A.M.--UTC (28.04.2023)
                  </p> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}

      {/* Bonjour maintenance info Modal */}
    {/* <div className="modal fade autopool_modal autopool_modal_chgs" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="whole_div" >
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <img
                src={require("../assets/images/pop_img.png")}
                className="img-fluid"
                alt="Icon"
              />
            </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* pop up  */}
          {/* <div className="modal fade autopool_modal autopool_modal_v1" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal_title">
                <img
                  src={require("../assets/images/announcement_speaker.png")}
                  className="img-fluid"
                  alt="Icon"
                />
                <h2>Dear Bonjour Community Members,</h2>
              </div>

              <div className="bonjour_modal_full bonjour_modal_full_chg">
                <div className="bonjour_autopool_modalinfo">
                   
                  <p className="popup_para heads">Exciting Announcement: </p>
                  <p className="popup_para heads mt-0">Bonjour Exchange Platform is Live Now!</p>
                  <a href="https://exchange.webonjour.com/" target="_blank" className="primary_btn buttn" type="button">
                   Bonjour Exchange
                    </a>
                    <p className="popup_para heads mt-0">Live testing is going ON, Sorry for the Inconvenience</p>
                    <p className="popup_para heads mt-0">Thanks</p>
                    <p className="popup_para heads mt-0">Bonjour Team</p>
                </div>
                 
                <div className="bonjour_presale_modalinfo">                  
                  <p className="popup_para">
                    We are really Happy to Announce that for Each and Every 5
                    Direct IDs you will get 50 BUSD instantly, Use this
                    Wonderful Opportunity to Grow our Community Much Bigger…
                  </p>
                  <p className="popup_para">                    
                      This offer is <span>Valid for Next 7 Days</span>
                  </p>
                </div> 
              </div>
              <div className="modal_footer"> 
                <h1>Bonjour</h1>               
                <h2>We are Growing Together !!!!!</h2>
              </div> 
            </div>
          </div>
        </div>
      </div> */}

      {/* Bonjour maintenance info Modal */}
     {/* <div className="modal fade autopool_modal" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal_title">
                <img
                  src={require("../assets/images/announcement_speaker.png")}
                  className="img-fluid"
                  alt="Icon"
                />
                <h2>Dear Bonjour Community Members,</h2>
              </div>

              <div className="bonjour_modal_full bonjour_modal_full_chg align">
                <div className="bonjour_autopool_modalinfo">
                  <p className="popup_para">Additionally, we are excited to announce the launch of a new contract on our website on 12.07.2023 (Wednesday) and All existing users can able to claim again. This new contract will introduce some changes to the withdrawal limits and returns. The updated plan is as follows:
                  </p>
                  <p className="popup_para">
                    Per Day Claim Limit:
                  </p>
                  <p className="popup_para mb-0">
                  Maximum: Unlimited
                  </p>
                  <p className="popup_para mb-0">
                  Minimum: $10
                  </p>
                  <p className="popup_para mb-0">
                  Daily ROI: 0.5%
                  </p>
                  <p className="popup_para mb-0">
                  Spot: $10
                  </p>
                  <p className="popup_para mb-0">
                  Level Income: 0.75%
                  </p>

                  <p className="popup_para">

Furthermore, we have revised the level income structure to provide even greater earning potential for our investors. The updated level income distribution is as follows:
                  </p>
                   <p className="popup_para mb-0">
                   Level 1: 0.2%
                  </p>
                  <p className="popup_para mb-0">
                  Level 2: 0.15%
                  </p>
                  <p className="popup_para mb-0">
                  Level 3: 0.1%
                  </p>
                  <p className="popup_para mb-0">
                   Level 4: 0.1%
                                     </p>
                  <p className="popup_para mb-0">
                  Level 5: 0.1%
                  </p>
                  <p className="popup_para mb-0">
                  Level 6: 0.05%
                  </p>
                  <p className="popup_para mb-0">
                  Level 7: 0.05%
                  </p>

                  <p className="popup_para mb-0">
                  These changes have been implemented to ensure that you can maximize your returns while enjoying a sustainable and rewarding investment experience with Bonjour.

                  </p>

                </div>

                <div className="bonjour_presale_modalinfo">                  
                  
                  <ul className="ul" >
                  <li>
                   We are announcing you to provide you with some important updates regarding our upcoming live trading platform and the exciting opportunities for our investors.</li>
                  <li>
On Monday(10.07.2023), we will be launching the live trading platform, where you will have the chance to engage in trading activities with the following pairs: BNB/USDT, BUSD/USDT, and BNJR/BUSD. This is a significant milestone for us, and we are thrilled to embark on this new phase of our project.</li>
                  <li>In recognition of your investment and the trust you have placed in the Bonjour team, we have decided to reward your patience and loyalty with an airdrop based on your equivalent investment amount you will be airdropped on Friday(07.07.2023). 
</li>
                  <li>
Moving forward, we aim to ensure the long-term success of our project. Therefore, we want to inform you that claiming airdrops will no longer be restricted or halted. We have removed all limitations, and Bonjour will continue to thrive indefinitely.
      </li>
      <li>
      We are happy to announce that we can exchange BNJR/BUSD both in spot and Peer to peer exchange (P2P)
      </li>
                  </ul>
                  
                </div>
              </div>
              <div className="modal_footer mt-3"> 
                <p>Thank you once again for being a valued member of the Bonjour community. We look forward to a successful live trading platform launch and a prosperous journey ahead.
</p>               

                <h2>Warm regards <br />Bonjour Team</h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Bonjour Autopool Modal */}
      {/* <div className="modal fade autopool_modal" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <img
                src={require("../assets/images/logo_large.png")}
                className="img-fluid"
                alt="Icon"
              />
              <div className="bonjour_modal_full">
                <div className="bonjour_autopool_modalinfo">
                  <h1>Autopool</h1>
                  <h2 className="fontadj">
                    Invest 100 $ to 500 $ you will get an{" "}
                    <span className="airdrop_txt">"Airdrop"</span>
                    <span className="d-block">(Apr 04 to Apr 11 2023)</span>
                    of <span className="airdrop_txt">25000 BNJR</span>
                  </h2>
                  <h2 className="fontadj">
                    Min & Max investment is{" "}
                    <span className="d-block">10 $ to 500 $</span>
                  </h2>
                </div>
                <div className="bonjour_presale_modalinfo">
                  <h1>Presale is live now</h1>
                  <h2 className="fontadj">
                    only limited amount of tokens <br />
                    will ends on <span className="d-block">07.04.2023</span>
                  </h2>
                  <a href="https://presale.webonjour.com/" target="_blank">
                    <button className="primary_btn buttn" type="button">
                      BUYNOW
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Bonjour Modal announcement 16-june-2023 */}
     <div className="modal fade autopool_modal autopool_modal_chg bonjour_popup_whole announcement-dark" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
             
             <div className="row">
              <div className="col-lg-7">
                <div className="leftPopupContent">
                  <h2>Bonjour<span>Announcement</span></h2>
                  <br />
                  <div>
                  <h4 className="text-center" >Dear Bonjour Community Members,</h4>

                  <h4 className="text-light text-center" >Bonjour BNJR Token Airdrop is Live Now , Eligible users can Claim</h4>
                  </div> 
                    <br />

                    {/*<li>Spot <span>30%</span> (10$ to 40$)</li>
                    <li>Min withdrawal is 15$</li>
                    <li>Investors those who invested 500 BUSD and above can do claim from coming Wednesday.</li>*/}
                  <p className="signature-text">
                    <label>Thank you once again for being a valued member of the Bonjour community</label>
                    <span>Warm regards <br /> Bonjour Team</span>
                    <a href="https://webonjour.com/">www.webonjour.com</a>
                  </p>

                </div>
              </div>
              <div className="col-lg-5">
                <div className="rightImagePOpup">
                  <img
                    src={require("../assets/images/bonjure-popup-right.png")}
                    className="img-fluid"
                    alt="Icon"
                  />
                </div>
              </div>
             </div>
             <img
                    src={require("../assets/images/bonjure-popup-logo.png")}
                    className="img-fluid popLogo"
                    alt="Icon"
                  />
            </div>
          </div>
        </div>
      </div> 

      {/* Bonjour Autopool Modal announcement*/}
      {/* <div className="modal fade autopool_modal autopool_modal_chg bonjour_popup_whole" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <img
                src={require("../assets/images/popup_img.png")}
                className="img-fluid wallet_icon"
                alt="Icon"
              />
              <div className="respons" >
              <h1>Announcement</h1>
              <div className="bonjour_popup">
                 <h5>Dear Users, </h5>
                 <p>Here's an important update for you !</p>
                 <p>We talk about Bonjour's ecosystem all the time, but sometimes it gets hard for you to follow it. Your heart wants to invest, but your pockets need more. But this ends up giving you less in the long run. We understand the struggle. Here's the catch.</p>
                 <p>To maintain such proper balance in Bonjour's ecosystem,  we have crafted an amazing opportunity for you to get richer day by day. To withdraw any number of BUSD you want, you just have to maintain the equivalent value of Bonjour tokens in your wallet. </p>
                 <p>When daily withdrawals give you less money, this balance gives you more. Because, you will also have more Bonjour tokens at the same time. As the value of Bonjour tokens is rapidly getting higher, guess who's gonna be richer ? </p>
                 <p>It's YOU ! </p>
                 <p>So, let's never compromise on the balance !</p>
                 <h5 className="font" >"The above update will be implemented from Friday morning"</h5>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Bonjour Autopool Modal */}
      {/* <div className="modal fade autopool_modal" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-end">
                <button
                  type="button"
                  className="btn-close autopool_modal_close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <img
                src={require("../assets/images/logo_large.png")}
                className="img-fluid"
                alt="Icon"
              />
              <div className="bonjour_modal_full bonjour_modal_full_chg">
                <div className="bonjour_presale_modalinfo">
                  <h1>Here’s the catch !</h1>
                  <h1 className="duplicate">Here’s the catch !</h1>
                  <h2>AN OPPORTUNITY TO ADD 15% TO YOUR INCOME !</h2>
                  <p className="popup_para">
                    If an user invests in these three arenas at the same time,
                    by adding one ID in a single day, you will receive 10%, 5%
                    income through Bonjour Plan & Autopool
                  </p>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="box">
                        <img
                          src={require("../assets/images/one.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h5>Basic Bonjour Plan</h5>
                        <p>Minimum 10$ to Maximum Unlimited</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="box">
                        <img
                          src={require("../assets/images/two.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h5>Autopool </h5>
                        <p>Minimum 10$ to Maximum Unlimited</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="box">
                        <img
                          src={require("../assets/images/three.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <h5>Tokens</h5>
                        <p>Minimum 10$ to Maximum Unlimited</p>
                      </div>
                    </div>
                  </div>
                  <p className="popup_para blw">
                    The Consecutive users will receive 5% to 10% of income based
                    on your referral levels.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Bonjour Autopool Modal */}
      {/* <div className="modal fade autopoollive_modal" id="autopool_info">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="autopoolban">
                <div className="autopool_box">
                  <h1>Autopool</h1>
                </div>
              </div>
              {<Countdown date={`2023-03-31T20:00:59`} renderer={renderer} />}
              <div className="text-center mt-5">
                <a href="https://webonjour.com/bonjourpool" target="_blank">
                  <button className="primary_btn buttn" type="button">
                    Click Here
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
